import Events from './events';
require( 'owl.carousel' );

jQuery( document ).ready(function($) {
    Events();

    $( '.more-music' ).owlCarousel( {
		items: 1,
		loop: true,
		nav: true,
		dots: false,
		margin: 10,
		navText:[
			'<img src = "./dist/img/arrow.png" alt = "previous">',
			'<img src = "./dist/img/arrow.png" alt = "next">'
		],
	});

    $( '.video-carousel' ).owlCarousel( {
		items: 4,
		loop: true,
		nav: false,
		dots: false,
		margin: 30,
        responsive:{
            0:{
                margin: 20,
                items: 1,
                stagePadding: 50,
            },
            600:{
                margin: 20,
                items: 2,
                stagePadding: 50,
            },
            900:{
                margin: 30,
                items: 4,
                stagePadding: 0,
            }
        }
	});

    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');
    
        e.preventDefault();
    
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top - 50
        });
    });

	$('.newsletter form').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    function rowStart(){
        var top = 0;
        $('.row-start').removeClass('row-start');
        $('#tracks').children().each(function(index, item){                 
            console.log($(item).offset().top)
            if(top != $(item).offset().top){
                top = $(item).offset().top;
                $(item).addClass('row-start');
                if(top != $(item).offset().top){
                    $(item).removeClass('row-start');
                    top = $(item).offset().top;
                }
            }
        });
    }

    rowStart();
    
    $(window).on('resize', function() {
        rowStart();
    });
    $(window).on('load', function() {
        console.log('Loaded')
        setTimeout(rowStart(),500);
    });

    $(window).on('scroll', function(){
        if ($(document).scrollTop() > $('#music').offset().top){
            $('#header .title').removeClass('hide');
        } else {
            $('#header .title').addClass('hide');
        }
    })


    $('.menu-toggle').on('click', function(e){
        e.preventDefault();
        $('.menu-toggle').toggleClass('open')
        $('.nav').toggleClass('open')
    })

    $('.nav a').on('click',function(){
        $('.menu-toggle').toggleClass('open')
        $('.nav').toggleClass('open')
    })
});
import momemt from 'moment';

export default function Events() {
	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/h.e.r./events?app_id=45PRESS',
		method: 'GET',
		dataType: 'json',
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';
			console.log( data );
			if ( data.length ) {
				for ( let event of data ) {
					const location = event.venue.city + ', ' + event.venue.region;
					html += '<div class="event">';
					html += '<div class="event-date">' + momemt( event.datetime ).format( 'dddd MMMM DD, ' ) + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-links">';
					for ( let offer of event.offers ) {
						html += '<a href="' + offer.url + '" target="_blank">Buy Tickets <img src="./dist/img/arrow.png" alt=""></a>';
					}
					html += '</div>';
					html += '</div>';
				}
				html+='<div class="more"><a href="#">view more dates</a></div>';
				events.html( html );

				$( '#events .more' ).hide();
				if ( $( '#events>div' ).length > 3 ) {
					$( '#events .more' ).show();
				}
			} else {
				events.html( 'No upcoming events.' );
			}
			$( '#events .more a' ).on( 'click', function ( e ) {
				e.preventDefault();
				$( '#events' ).addClass('seeall');
				$( this ).hide();
			} );
		},
	} );

	

}